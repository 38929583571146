import { Comuna } from 'class/*';
import { APIComuna } from 'src/app/interface';

export function APIComunaToComuna(apiComuna: APIComuna): Comuna {
  return {
    id: apiComuna.id,
    nombre: apiComuna.nombre,
    regionId: apiComuna.region_id,
  };
}
