import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { switchMap, first } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import * as moment from 'moment';

import { UsuarioService } from '.';

import { LoginApi } from '..';

@Injectable()
export class AuthService {
  set accessToken(value: string) {
    if (!value) {
      localStorage.removeItem('access_token');
      return;
    }
    localStorage.setItem('access_token', value);
  }

  /**
   * Obtener el token de acceso del local storage.
   */
  get accessToken(): string {
    return localStorage.getItem('access_token');
  }

  constructor(
    private http: HttpClient,
    private usuarioService: UsuarioService,
    private router: Router,
  ) {}

  public login(email: string, password: string) {
    return this.http
      .post<LoginApi>(`${environment.url_api}/dashboard/signin`, {
        email,
        password,
      })
      .pipe(
        first(),
        switchMap((response) => {
          this.accessToken = response.token;
          return this.usuarioService.getUsuario(this.accessToken);
        }),
      );
  }

  public logout() {
    localStorage.clear();
    this.usuarioService.usuario = null;
    this.router.navigate(['login']);
  }

  public isAuthenticated(): boolean {
    const payload = this.getPayloadFromToken(this.accessToken);
    let hoy_unix = moment().utc().unix();

    if (
      payload && // Si se pudo extraer el payload
      payload.exp && // Si existe el tiempo de expiración
      moment.unix(payload.exp).isAfter(moment.unix(hoy_unix)) // Si el token aún no ha expirado
    ) {
      // Token disponible
      return true;
    } else {
      return false;
    }
  }

  private getPayloadFromToken(token: string) {
    try {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return JSON.parse(jsonPayload);
    } catch {
      return null;
    }
  }
}
