import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { AuthService } from "src/app/modules/core/services";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  isLoggedIn$: Observable<boolean>;
  isLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(["/login"]);
      return false;
    } else {
      return true;
    }
  }
}
