import { Region } from 'class/*';
import { APIRegion } from 'src/app/interface';

export function APIRegionToRegion(apiRegion: APIRegion): Region {
  return {
    id: apiRegion.id,
    nombre: apiRegion.nombre,
    paisId: apiRegion.pais_id,
  };
}
