import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';

import { map, pluck } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { APIRegionToRegion } from './api-to/api-region-to-region';
import { APIComunaToComuna } from './api-to/api-comuna-to-comuna';

@Injectable()
export class UtilitarioService {
  constructor(private http: HttpClient) {}

  getPlanes() {
    return this.http.get<any>(`${environment.url_api}/dashboard/planes`).pipe(
      map((res) => {
        return res.body;
      }),
    );
  }

  // Países asignados a alguna propiedad
  getPaises() {
    return this.http.get<any>(`${environment.url_api}/dashboard/paises`).pipe(
      map((res) => {
        return res.body;
      }),
    );
  }

  getRegiones(paisId: number) {
    return this.http
      .get<any>(`${environment.url_api}/paises/${paisId}/regiones`)
      .pipe(map((res) => res.map(APIRegionToRegion)));
  }

  getComunas(regionId: number) {
    return this.http
      .get<any>(`${environment.url_api}/regiones/${regionId}/comunas`)
      .pipe(map((res) => res.map(APIComunaToComuna)));
  }

  getZonasHorarias() {
    return this.http.get<any>(`${environment.url_api}/zonas_horarias`).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
