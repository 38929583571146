import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';

import { first, map, pluck } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Propiedad } from 'class/*';

@Injectable()
export class PropiedadService {
  private propiedadesSubject = new BehaviorSubject<Propiedad[]>(null);

  /**
   * Stream para subscribirse a todas las propiedades de GoFeels
   */
  propiedades$ = this.propiedadesSubject.asObservable();

  constructor(private http: HttpClient) {}

  set propiedades(value: Propiedad[]) {
    this.propiedadesSubject.next(value);
  }

  /**
   * Cliente logueado actualmente.
   */
  get propiedades(): Propiedad[] {
    return this.propiedadesSubject.value;
  }

  /**
   * Actualiza los datos de la propiedad
   * @param propiedad_id
   * @param data Datos a actualizar
   */
  actualizarPropiedad(propiedad_id: number, data: any) {
    return this.http
      .put<any>(
        `${environment.url_api}/dashboard/propiedades/${propiedad_id}`,
        data,
      )
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }

  /**
   * Obtiene las propiedades por filtro personalizado
   */
  getPropiedades(params: any) {
    return this.http
      .get<any>(`${environment.url_api}/dashboard/propiedades${params}`)
      .pipe(
        map((res) => {
          return res.body;
        }),
      );
  }

  /**
   * Obtiene el detalle de la propiedad
   * @param propiedad_id
   */
  getPropiedad(propiedad_id: number) {
    return this.http
      .get<any>(`${environment.url_api}/dashboard/propiedades/${propiedad_id}`)
      .pipe(
        map((res) => {
          return res.body;
        }),
      );
  }

  getPropertiesByName(nombre_propiedad: string) {
    return this.http
      .get<any>(
        `${environment.url_api}/dashboard/propiedades/busqueda?nombre=${nombre_propiedad}`,
      )
      .pipe(
        first(),
        map((res) => {
          return res.body;
        }),
      );
  }

  /**
   * "Elimina" la propiedad de prueba para no interferir con las propiedades reales
   * @param propiedad_id
   * @param eliminado [0, 1]
   */
  editarEstadoEliminadoPropiedad(propiedad_id: number, eliminado: number) {
    return this.http
      .put<any>(
        `${environment.url_api}/dashboard/propiedades/${propiedad_id}/desactivar`,
        {
          eliminado: eliminado,
        },
      )
      .pipe(
        map((res) => {
          return res.body;
        }),
      );
  }

  getCuentaBancariaGoFeelsPayNacional(propiedad_id: number) {
    return this.http
      .get<any>(
        `${environment.url_api}/motor/propiedades/${propiedad_id}/metodos_pago?metodo_pago_motor_id=7`,
      )
      .pipe(
        first(),
        pluck('body', 'cuenta_bancaria'),
        map((res) => {
          return res;
        }),
      );
  }

  importReservationsFromBookingDotCom(propertyId: number) {
    return this.http
      .post(`${environment.url_api}/booking-reservation-imports`, {
        property_id: propertyId,
      })
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }

  createBookingEngineDomainConfiguredProperty(propertyId: number) {
    return this.http.post(
      `${environment.url_api}/booking-engine-domain-configured-properties`,
      {
        property_id: propertyId,
      },
    );
  }

  deleteBookingEngineDomainConfiguredProperty(propertyId: number) {
    return this.http.delete(
      `${environment.url_api}/booking-engine-domain-configured-properties/${propertyId}`,
    );
  }

  updatePropertyReservationEngineVersion(propertyId: number, version: string) {
    return this.http.put<any>(
      `${environment.url_api}/reservation-engine-version/${propertyId}`,
      {
        version: version,
      },
    );
  }

  removeUserFromProperty(propertyId: number, userId: number) {
    return this.http.delete<any>(
      `${environment.url_api}/dashboard/propiedades/${propertyId}/users/${userId}`,
    );
  }

  addUserToProperty(propertyId: number, userId: number) {
    return this.http
      .put<any>(
        `${environment.url_api}/dashboard/usuarios/${userId}/actualizar_propiedad`,
        { propiedad_id: propertyId },
      )
      .pipe(
        first(),
        // map((res: any) => res)
      );
  }
}
