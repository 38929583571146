import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';

import { map, pluck } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable()
export class DashboardService {
  constructor(private http: HttpClient) {}

  getPropiedadesProcesoRegistro(fecha_inicio: string, fecha_fin: string) {
    return this.http
      .get<any>(
        `${environment.url_api}/dashboard/totales/pasos/propiedades/${fecha_inicio}/${fecha_fin}`,
      )
      .pipe(
        map((res) => {
          return res.body;
        }),
      );
  }
}
