import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router,
  UrlTree
} from "@angular/router";
import { AuthService } from "src/app/modules/core/services";

@Injectable({ providedIn: "root" })
export class NotLoggedInGuard implements CanActivate {
  
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (!this.authService.isAuthenticated()) {
      return true;
    } else {
      this.router.parseUrl("/");
      return false;
    }
  }
}
