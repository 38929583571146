import { AuthService, UsuarioService } from './modules/core/services';

export function appInitializer(
  authService: AuthService,
  usuarioService: UsuarioService,
) {
  return () =>
    new Promise<void>((resolve) => {
      // Fix: Added void type to Promise
      if (authService.isAuthenticated()) {
        usuarioService
          .getUsuario(authService.accessToken)
          .subscribe((usuario) => {
            usuarioService.usuario = usuario;
          })
          .add(resolve);
      } else {
        localStorage.clear();
        usuarioService.usuario = null;
        resolve();
      }
    });
}
