import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';

import { map, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class GofeelsPayService {
  constructor(private http: HttpClient) {}

  /** Ordenes */

  getOrdenes(page: number) {
    return this.http
      .get<any>(
        `${environment.url_api}/dashboard/payment-gateway-transactions`,
        {
          params: {
            page,
            order_by: 'id.desc',
          },
        },
      )
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }

  private buildFiltroUrl(
    filtro: any,
    page: number,
    pageSize: number,
    orderby: string,
    propiedadId: number,
    international: boolean,
    link_charge: boolean,
  ) {
    let filtro_url =
      filtro === null ?
        `?page=${page}&por_pagina=${pageSize}&orderby=${orderby}`
      : `?${filtro}&page=${page}&por_pagina=${pageSize}&orderby=${orderby}`;

    if (propiedadId) {
      filtro_url += `&propiedad_id=${propiedadId}`;
    }

    if (international) {
      filtro_url += `&international`;
    }

    if (link_charge) {
      filtro_url += `&link_charge`;
    }

    return filtro_url;
  }

  private getPagos(
    endpoint: string,
    filtro: any,
    page: number,
    pageSize: number,
    orderby: string,
    propiedadId: number,
    international: boolean,
    link_charge: boolean = false,
  ) {
    const filtro_url = this.buildFiltroUrl(
      filtro,
      page,
      pageSize,
      orderby,
      propiedadId,
      international,
      link_charge,
    );
    return this.http
      .get<any>(
        `${environment.url_api}/dashboard/gofeelspay/${endpoint}${filtro_url}`,
      )
      .pipe(map((res) => res));
  }

  /**
   * Obtención de pagos realizados desde el motor de reserva
   */
  getPagosGofeelsPayMotor(
    filtro: any,
    page: number,
    pageSize: number,
    orderby: string,
    propiedadId: number,
    international: boolean,
  ) {
    return this.getPagos(
      'motor_reservas/pagos',
      filtro,
      page,
      pageSize,
      orderby,
      propiedadId,
      international,
    );
  }

  /**
   * Obtención de pagos vinculados a un link de cobro
   */
  getPagosGofeelsPayLinkCobro(
    filtro: any,
    page: number,
    pageSize: number,
    orderby: string,
    propiedadId: number,
    international: boolean,
  ) {
    return this.getPagos(
      'link_cobro/pagos',
      filtro,
      page,
      pageSize,
      orderby,
      propiedadId,
      international,
      true,
    );
  }

  /**
   * Obtencion de comisiones
   *
   */

  private getTotalComisiones(
    endpoint: string,
    filtro: any,
    propiedadId: number,
    international: boolean,
    link_charge: boolean = false,
  ) {
    const filtro_url = this.buildFiltroUrl(
      filtro,
      null,
      null,
      null,
      propiedadId,
      international,
      link_charge,
    );

    return this.http
      .get<any>(
        `${environment.url_api}/dashboard/gofeelspay/motor_reservas/total_comisiones${filtro_url}`,
      )
      .pipe(map((res) => res));
  }

  /**
   * Obtencion de comisiones desde el motor de reserva
   */
  getTotalComisionesGofeelsPayMotor(
    filtro: any,
    propiedadId: number,
    international: boolean,
  ) {
    return this.getTotalComisiones(
      'motor_reservas/total_comisiones',
      filtro,
      propiedadId,
      international,
    );
  }

  /**
   * Obtencion de comisiones link de cobro
   */
  getTotalComisionesGofeelsPayLinkCharge(
    filtro: any,
    propiedadId: number,
    international: boolean,
  ) {
    return this.getTotalComisiones(
      'link_cobro/total_comisiones',
      filtro,
      propiedadId,
      international,
      true,
    );
  }

  /**
   * Obtencion de listado de propiedad que tuvieron pagos
   */
  getPropiedadesConPago(fechaInicio: string, fechaFin: string) {
    return this.http
      .get<any>(
        `${environment.url_api}/dashboard/gofeelspay/motor_reservas/propiedades-con-pagos?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&orderby=nombre.asc`,
      )
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }

  /**
   * Obtencion de pagos realizados desde el motor de reserva
   * @param data { pagos: [ids] }
   */
  confirmarPagosGofeelsPayMotor(data: any) {
    return this.http
      .post<any>(
        `${environment.url_api}/dashboard/gofeelspay/confirmar_pagos`,
        data,
      )
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }

  confirmarPagosGofeelsPayLinkCobro(data: any) {
    return this.http
      .post<any>(
        `${environment.url_api}/dashboard/gofeelspay/confirmar_pagos_link_cobro`,
        data,
      )
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }

  /**
   * Obtencion de pagos realizados desde el motor de reserva
   */
  getPagosGofeelsPayModulos() {
    return this.http
      .get<any>(`${environment.url_api}/dashboard/pagos/modulos`)
      .pipe(
        map((res) => {
          return res.pagos;
        }),
      );
  }
}
