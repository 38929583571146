import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './modules/shared/material/material.module';

import * as Services from 'src/app/modules/core/services';

import * as Interceptors from './modules/core/interceptors';
import { appInitializer } from './app.initializer';

import { AppComponent } from './app.component';

import { AbilityModule } from '@casl/angular';
import { Ability, PureAbility } from '@casl/ability';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    AbilityModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: appInitializer,
      deps: [Services.AuthService, Services.UsuarioService],
    },
    {
      provide: Ability,
      useValue: new Ability(),
    },
    {
      provide: PureAbility,
      useExisting: Ability,
    },
    // Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptors.JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptors.HttpErrorInterceptor,
      multi: true,
    },
    // Services
    Services.AuthService,
    Services.UsuarioService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
